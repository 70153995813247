.rentennials-phone-input {
  height: 50px;
  display: flex;
}

.rentennials-phone-input .PhoneInput {
  display: flex;
  column-gap: 0px;
}

.rentennials-phone-input > div > input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #e2e8f0;
  background: #ededed;
  padding: 0 16px;
  font-family: 'Bree Serif';
  font-weight: 400;
  font-size: 12px;
  color: #757575;
}

.rentennials-phone-input > div > div:has(> select:focus) {
  border-color: #6141d8;
  outline: #6141d8 auto 1px;
  border-radius: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.rentennials-phone-input > div > input:focus-visible {
  border-color: #6141d8;
  outline: #6141d8 auto 1px;
  border-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.rentennials-phone-input > div > div {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #e2e8f0;
  background: #ededed;
  padding: 0 8px;
}

.rentennials-phone-input.invalid > div > div,
.rentennials-phone-input.invalid > div > input {
  border-color: #ef693a;
  outline: #ef693a auto 1px;
}
.rentennials-phone-input.disabled {
  opacity: 0.5;
}

.rentennials-phone-input > div > div:hover,
.rentennials-phone-input input:hover {
  border: 1px solid #cbd5e0;
}

.rentennials-phone-input input {
  height: 100%;
}

.rentennials-phone-input .PhoneInputCountryIcon:focus,
.rentennials-phone-input .PhoneInputCountryIcon:hover,
.rentennials-phone-input .PhoneInputCountryIcon {
  border: unset;
  box-shadow: unset;
}
