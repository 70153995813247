.rentennials-picker {
  z-index: 20;
}

.rentennials-picker input:focus-visible {
  border-color: #6141d8;
  box-shadow: 0 0 0 1px #6141d8 !important;
}
.rentennials-picker input[aria-invalid='true'] {
  border-color: #ef693a;
  box-shadow: 0 0 0 1px #ef693a;
}

.rentennials-picker .datepicker-calendar > div,
.rentennials-picker .datepicker-calendar > div > div > div {
  border: unset;
}

.rentennials-picker.input .datepicker-calendar > div {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #f3f4f6;
  box-shadow: 0 0 0 1px #f3f4f6;
  padding: 20px;
}

.rentennials-picker .datepicker-calendar > div * button {
  font-size: 14px;
  line-height: 16px;
  color: #6141d8;
  font-family: 'Roboto';
  font-weight: 600;
}

.rentennials-picker .datepicker-calendar * button[aria-pressed='true'],
.rentennials-picker .datepicker-calendar > div * button:hover {
  color: #ffff;
  background-color: #6141d8;
}

.rentennials-picker .datepicker-calendar > div * h2,
.rentennials-picker div[id^='popover-body'] > div * h2 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Bree Serif';
}

.rentennials-picker .datepicker-calendar > div * {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Bree Serif';
}
